import AspectRatioBox from "./AspectRatioBox";
import Box from "./Box";
import { DARK } from "../core/constants";
import Image from "./Image";
import LinkButtonPrimary from "./LinkButtonPrimary";
import PropTypes from "prop-types";
import React from "react";
import { getMedia } from "../core/utils";

function HomepageHeroRootCategory({ image, url, buttonText }) {
  return (
    <AspectRatioBox pb="115%">
      <Image
        src={getMedia(image.id, "fill-740x850-c0")}
        sizes="(max-width: 960px) 100vw, 50vw"
        alt={image.title}
        objectFit="cover"
        position="absolute"
        height="100%"
        width="100%"
        zIndex={1}
        bg="lightGrey"
      />
      <Box
        position="absolute"
        height="100%"
        width="100%"
        top={0}
        left={0}
        display="flex"
        alignItems="flex-end"
        zIndex={1}
        p={[20, null, 30, null]}
      >
        <LinkButtonPrimary theme={DARK} href={url}>
          {buttonText}
        </LinkButtonPrimary>
      </Box>
    </AspectRatioBox>
  );
}

export default HomepageHeroRootCategory;

HomepageHeroRootCategory.propTypes = {
  image: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};
