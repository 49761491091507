import Box from "./Box";
import HomepageHero from "./HomepageHero";
import HomepageMission from "./HomepageMission";
import HomepagePartners from "./HomepagePartners";
import HomepagePromoArea from "./HomepagePromoArea";
import HomepageTicker from "./HomepageTicker";
import LatestDrop from "./LatestDrop";
import Layout from "./Layout";
import PageContent from "./PageContent";
import React from "react";
import ThreeRs from "./ThreeRs";
import InstagramPromo from "./InstagramPromo";

function HomePage({ pageData, navItems, globalData, brands, latestDrop }) {
  return (
    <Layout navItems={navItems} globalData={globalData} pageTheme="lilac">
      <PageContent>
        <HomepageHero
          heading={pageData.heading}
          womenswearImage={pageData.heroImageWomenswear}
          menswearImage={pageData.heroImageMenswear}
        />
        <Box bg="white" pt={["164%", "25.5%", null, null]}>
          <HomepageMission
            heading={pageData.missionHeading}
            text={pageData.missionText}
          />
        </Box>

        {latestDrop.products.length > 0 && (
          <Box mb={[32, null, 80, null]}>
            <LatestDrop
              title={pageData.latestDropTitle}
              latestDrop={latestDrop}
            />
          </Box>
        )}
        <ThreeRs items={pageData.threeRsBlock} />
        {pageData.ticker.length > 0 && (
          <HomepageTicker items={pageData.ticker} />
        )}
        <HomepagePartners brands={brands} />
        {pageData.promoArea && <HomepagePromoArea data={pageData.promoArea} />}
        {pageData.instagramPromo.length > 0 && (
          <InstagramPromo items={pageData.instagramPromo} />
        )}
      </PageContent>
    </Layout>
  );
}

export default HomePage;
