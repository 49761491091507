import Box from "./Box";
import React, { useRef } from "react";
import PropTypes from "prop-types";
import SquareImage from "./SquareImage";
import { getMedia } from "../core/utils";
import ReskinnedSwiper from "./ReskinnedSwiper";
import { useHovered } from "../core/hooks";

function InstagramLink({ image, href }) {
  const ref = useRef(null);
  const hovered = useHovered(ref);

  return (
    <a href={href} target="_blank">
      <Box position="relative" ref={ref} height={0} pb="100%" overflow="hidden">
        <Box
          transition="transform 300ms ease-out"
          transform={hovered ? "scale3d(1.05,1.05,1.05)" : "scale3d(1,1,1)"}
        >
          <SquareImage
            src={getMedia(image.id, "fill-800x800-c0")}
            sizes="(max-width: 640px) 70vw, (max-width: 960px) 40vw, 25vw"
            alt={image.title}
          />
        </Box>
      </Box>
    </a>
  );
}

function InstagramPromo({ items }) {
  const slidesPerView = 4.1;
  return (
    <Box bg="mushroom" pt={[50, null, 120, null]} pb={[50, 96, 130, null]}>
      <ReskinnedSwiper
        heading="Pre-loved community –"
        items={items.map((item, index) => {
          return (
            <InstagramLink
              key={index}
              image={item.value.image}
              href={item.value.link}
            />
          );
        })}
        slidesPerView={slidesPerView}
        extraVerticalSpace
      />
    </Box>
  );
}

InstagramPromo.propTypes = {
  items: PropTypes.array.isRequired,
};

export default InstagramPromo;
