import Box from "./Box";
import PropTypes from "prop-types";
import React from "react";
import Text from "./Text";
import Ticker from "./Ticker";

function HomepageTicker({ items }) {
  return (
    <Ticker
      height={[100, null, 200, null]}
      bg="white"
      pixelsPerSecond={550}
      tickerItem={
        <Box display="flex">
          {items.map((item) => {
            return (
              <Text
                key={item.value}
                fontSize={[32, 32, 62, 62]}
                fontFamily="ibmPlexSans"
                whiteSpace="nowrap"
                pr={[30, null, 80, null]}
              >
                {item.value}
              </Text>
            );
          })}
        </Box>
      }
    />
  );
}

export default HomepageTicker;

HomepageTicker.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};
