import Box from "./Box";
import BoxWithGutter from "./BoxWithGutter";
import HeadingSmall from "./HeadingSmall";
import React from "react";
import RichTextLarge from "./RichTextLarge";
import TwoColBox from "./TwoColBox";
import DashHeading from "./DashHeading";
import IndentedParagraphMedium from "./IndentedParagraphMedium";

function HomepageMission({ heading, text }) {
  return (
    <Box bg="white" pb={[65, null, 100, null]}>
      <BoxWithGutter pt={[40, null, 100, null]}>
        <DashHeading heading={heading} />
        <Box
          mt={[30, null, 60, null]}
          maxWidth={[null, "80vw", null, null]}
          mx="auto"
        >
          <IndentedParagraphMedium>{text}</IndentedParagraphMedium>
        </Box>
      </BoxWithGutter>
    </Box>
  );
}

export default HomepageMission;
