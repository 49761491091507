import Box from "./Box";
import Image from "./Image";
import PropTypes from "prop-types";
import React from "react";

function ReImage({ src, title, active }) {
  return (
    <Box opacity={active ? 1 : 0} transition="opacity 300ms ease-in">
      <Image
        src={src}
        alt={title}
        height="100%"
        width="100%"
        position="absolute"
        top={0}
        left={0}
        objectFit="cover"
        bg="lightGrey"
      />
    </Box>
  );
}

export default ReImage;

ReImage.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
};
