import Box from "./Box";
import BrandSwiper from "./BrandSwiper";
import React from "react";

function HomepagePartners({ brands }) {
  return (
    <Box bg="yellow" pt={[50, null, 120, null]} pb={[50, 96, 130, null]}>
      <BrandSwiper heading="Takeback partners –" brands={brands} />
    </Box>
  );
}

export default HomepagePartners;
