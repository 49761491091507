import BoxWithGutter from "./BoxWithGutter";
import PromoBlock from "./PromoBlock";
import PropTypes from "prop-types";
import { RIGHT } from "../core/constants";
import React from "react";
import { getMedia } from "../core/utils";

function HomepagePromoArea({
  data: { promoTitle, promoText, promoImage, ctaLink, ctaButtonText },
}) {
  const image = {
    src: getMedia(promoImage.id, "fill-760x760-c0"),
    alt: promoImage.title,
  };

  return (
    <BoxWithGutter my={[30, null, 80, null]}>
      <PromoBlock
        heading={promoTitle}
        text={promoText}
        image={image}
        imagePos={RIGHT}
        ctaLink={ctaLink}
        ctaText={ctaButtonText}
        externalLink
      />
    </BoxWithGutter>
  );
}

export default HomepagePromoArea;

HomepagePromoArea.propTypes = {
  data: PropTypes.shape({
    promoTitle: PropTypes.string.isRequired,
    promoText: PropTypes.string.isRequired,
    promoImage: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    ctaLink: PropTypes.string,
    ctaButtonText: PropTypes.string,
  }).isRequired,
};
