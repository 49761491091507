import Box from "./Box";
import Image from "./Image";
import { RESPONSIVE } from "../core/constants";
import React from "react";

function BuyWearReturnRepeat() {
  return (
    <Box
      height="100%"
      width="100%"
      position="absolute"
      top={0}
      left={0}
      bg="black"
      borderRadius="100%"
    >
      <div
        className="spin spin--20"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          src="/images/Repeat-Circle.svg"
          alt="Buy wear return repeat"
          layout={RESPONSIVE}
          imgHeight={256}
          imgWidth={255}
          height="85%"
          width="85%"
        />
      </div>
    </Box>
  );
}

export default BuyWearReturnRepeat;
