import { MENSWEAR_URL, WOMENSWEAR_URL } from "../core/urls";

import Box from "./Box";
import BoxWithGutter from "./BoxWithGutter";
import BuyWearReturnRepeat from "./BuyWearReturnRepeat";
import HeadingLarge from "./HeadingLarge";
import HomepageHeroRootCategory from "./HomepageHeroRootCategory";
import PropTypes from "prop-types";
import React from "react";

function HomepageHero({ heading, womenswearImage, menswearImage }) {
  return (
    <Box bg="mushroom" pt={[100, null, 120, null]}>
      <BoxWithGutter pb={[38, null, 60, null]}>
        <HeadingLarge uppercase>{heading}</HeadingLarge>
      </BoxWithGutter>
      <Box mb={["-164%", "-25.5%", null, null]}>
        <BoxWithGutter
          display="grid"
          gridTemplateColumns={["1fr", null, "1fr 1fr", null]}
          gridColumnGap="2.38%"
          gridRowGap={19}
          position="relative"
        >
          <Box
            position="absolute"
            width="100%"
            height="100%"
            top={0}
            left={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex={2}
            pointerEvents="none"
          >
            <Box
              display={["none", null, "initial", null]}
              width="15.4%"
              height={0}
              pb="15.4%"
              position="relative"
            >
              <BuyWearReturnRepeat />
            </Box>
          </Box>
          <HomepageHeroRootCategory
            image={womenswearImage}
            url={WOMENSWEAR_URL}
            buttonText="Shop womens"
          />
          <HomepageHeroRootCategory
            image={menswearImage}
            url={MENSWEAR_URL}
            buttonText="Shop mens"
          />
        </BoxWithGutter>
      </Box>
    </Box>
  );
}

HomepageHero.propTypes = {
  heading: PropTypes.string.isRequired,
  womenswearImage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  menswearImage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  rootCategories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ),
};

export default HomepageHero;
